import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import translation hook
import "../css/HeroSection.css";

// Define slides with static images
const slides = [
  {
    img: "https://i.ibb.co/0jJfs4F/1.webp",
  },
  {
    img: "https://i.ibb.co/jytXGkj/2.webp",
  },
  {
    img: "https://i.ibb.co/BjdxJQ6/4.webp",
  },
];

const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation(); // Access translations

  // Translate slide content dynamically
  const translatedSlides = t("hero.slides", { returnObjects: true });

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Slide changes every 5 seconds

    return () => clearInterval(slideInterval); // Cleanup interval on unmount
  }, []);

  return (
    <section
      className="hero-section"
      style={{
        backgroundImage: `url(${slides[currentSlide].img})`,
      }}
    >
      <div className="hero-overlay">
        <div className="hero-content">
          <h1>{translatedSlides[currentSlide].title}</h1>
          <p>{translatedSlides[currentSlide].description}</p>
          <button className="read-more">{t("hero.readMore")}</button>
        </div>
      </div>
      <div className="hero-controls">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`control-dot ${currentSlide === index ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </section>
  );
};

export default HeroSection;
