import React, { useState } from "react";
import {
  FaOilCan,
  FaDiagnoses,
  FaCarCrash,
  FaBatteryThreeQuarters,
  FaTools,
  FaWrench,
  FaTachometerAlt,
  FaCogs,
} from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Import translation hook
import "../css/ServicesOverview.css";

const ServicesOverview = () => {
  const [selectedService, setSelectedService] = useState(null); // State to track selected service
  const { t } = useTranslation(); // Access translations

  // Define services with icons and image URLs
  const services = [
    {
      id: "oilChange",
      image: "https://i.ibb.co/jf3XPBS/oil.webp",
      icon: <FaOilCan />,
    },
    {
      id: "engineDiagnostics",
      image: "https://i.ibb.co/MMhy8JT/32.webp",
      icon: <FaDiagnoses />,
    },
    {
      id: "brakeReplacement",
      image: "https://i.ibb.co/gJDfhcW/33.webp",
      icon: <FaCarCrash />,
    },
    {
      id: "batteryReplacement",
      image: "https://i.ibb.co/jJDcbJx/34.webp",
      icon: <FaBatteryThreeQuarters />,
    },
    {
      id: "suspensionRepairs",
      image: "https://i.ibb.co/JCymgXj/35.webp",
      icon: <FaTools />,
    },
    {
      id: "tireAlignment",
      image: "https://i.ibb.co/Nn9DZbG/36.webp",
      icon: <FaWrench />,
    },
    {
      id: "performanceTuning",
      image: "https://i.ibb.co/k9mQjvS/37.webp",
      icon: <FaTachometerAlt />,
    },
    {
      id: "engineRepair",
      image: "https://i.ibb.co/zscKCw3/38.webp",
      icon: <FaCogs />,
    },
  ];

  return (
    <section id="services" className="services-section">
      <h2>{t("servicesOverview.title")}</h2>
      <div className="services-list">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3>{t(`servicesOverview.${service.id}.title`)}</h3>
            <p>{t(`servicesOverview.${service.id}.description`)}</p>
            <button
              className="service-button"
              onClick={() => setSelectedService(service)} // Open modal with service details
            >
              {t("servicesOverview.learnMore")}
            </button>
          </div>
        ))}
      </div>

      {/* Modal for Service Details */}
      {selectedService && (
        <div className="service-modal">
          <div className="modal-content">
            <button
              className="modal-close"
              onClick={() => setSelectedService(null)} // Close modal
            >
              &times;
            </button>
            <img
              src={selectedService.image}
              alt={t(`servicesOverview.${selectedService.id}.title`)}
              className="modal-image"
            />
            <h3>{t(`servicesOverview.${selectedService.id}.title`)}</h3>
            <p>{t(`servicesOverview.${selectedService.id}.details`)}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServicesOverview;
