import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ServicesOverview from "./components/ServicesOverview";
import RepairProcess from "./components/RepairProcess";
import FAQ from "./components/FAQ";
import OurProducts from "./components/OurProducts";
import RequestCallback from "./components/RequestCallback";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import WhoWeAre from "./components/WhoWeAre";
import Statistics from "./components/Statistics";
import Proficiency from "./components/Proficiency";

function App() {
  return (
    <div className="App">
      <Header />

      <HeroSection />

      <WhoWeAre />
      
      <ServicesOverview />
      
      <RepairProcess />

      <Statistics />
      
      <OurProducts />
      <Proficiency />
      <FAQ />
      <RequestCallback />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
