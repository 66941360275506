import React from "react";
import { useTranslation } from "react-i18next"; // Importing translation hook
import "../css/Request.css";

const RequestCallback = () => {
  const { t, i18n } = useTranslation(); // Access translations

  // Dynamically set text direction based on the current language
  const isRTL = i18n.language === "ar";

  return (
    <section id="callback" className="callback-section" dir={isRTL ? "rtl" : "ltr"}>
      <div className="callback-container">
        {/* Left-Side Image */}
        <div className="callback-image">
          <img
            src="https://i.ibb.co/jW8DK1m/req.webp" // Replace with actual image URL
            alt={t("callback.imageAlt")}
          />
        </div>

        {/* Right-Side Text and Form */}
        <div className="callback-content">
          <h2>{t("callback.title")}</h2>
          <p className="callback-description">{t("callback.description")}</p>
          <form className="callback-form">
            <input type="text" placeholder={t("callback.form.name")} required />
            <input type="text" placeholder={t("callback.form.phone")} required />
            <input type="email" placeholder={t("callback.form.email")} required />
            <button type="submit">{t("callback.form.submit")}</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RequestCallback;
