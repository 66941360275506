import React from "react";
import { FaQuestionCircle, FaChevronDown } from "react-icons/fa"; // Importing icons
import { useTranslation } from "react-i18next"; // Import translation hook
import "../css/FAQ.css";

const FAQ = () => {
  const { t, i18n } = useTranslation(); // Access translations

  // Fetch FAQ data from translations
  const faqData = t("faq", { returnObjects: true });

  // Fallback for missing translation or data issues
  if (!faqData || !faqData.questions) {
    return <p>{t("faq.noData", { defaultValue: "FAQ data is not available." })}</p>;
  }

  return (
    <section id="faq" className="faq-section" dir={i18n.language === "ar" || i18n.language === "ku" ? "rtl" : "ltr"}>
      <h2>{faqData.title}</h2>
      <p className="faq-intro">{faqData.intro}</p>
      <div className="faq-list">
        {faqData.questions.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question">
              <FaQuestionCircle className="faq-icon" />
              <h3>{faq.question}</h3>
              <FaChevronDown className="faq-chevron" />
            </div>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
