import React from "react";
import { FaClipboardList, FaCalendarCheck, FaTools } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../css/RepairProcess.css";

const RepairProcess = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 1,
      icon: <FaClipboardList />,
      title: t("repairProcess.steps.1.title"),
      description: t("repairProcess.steps.1.description"),
    },
    {
      id: 2,
      icon: <FaCalendarCheck />,
      title: t("repairProcess.steps.2.title"),
      description: t("repairProcess.steps.2.description"),
    },
    {
      id: 3,
      icon: <FaTools />,
      title: t("repairProcess.steps.3.title"),
      description: t("repairProcess.steps.3.description"),
    },
  ];

  return (
    <section className="repair-process">
      <h2>{t("repairProcess.title")}</h2>
      <p className="repair-subtext">{t("repairProcess.subtext")}</p>
      <div className="steps">
        {steps.map((step) => (
          <div key={step.id} className="step">
            <div className="step-icon">{step.icon}</div>
            <span>{step.id}</span>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RepairProcess;
