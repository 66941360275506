import React from "react";
import "../css/Footer.css";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <div className="container">
        <div className="footer-left">
          <p className="footer-logo">{t("footer.logo")}</p>
          <p>{t("footer.copyright")}</p>
          <p>
            <FaPhone className="icon" /> {" "}
            <a href={`tel:${t("footer.phone")}`}>{t("footer.phone")}</a>
          </p>
          <p>
            <FaEnvelope className="icon" /> {" "}
            <a href={`mailto:${t("footer.email")}`}>{t("footer.email")}</a>
          </p>
          <p>
            <FaMapMarkerAlt className="icon" /> {" "}
            <a
              href={t("footer.mapLink")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.address")}
            </a>
          </p>
        </div>
        <div className="footer-right">
          <div className="map-container">
            <iframe
              className="map"
              src={t("footer.mapEmbedLink")}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="footer-socials">
          <a
            href={t("footer.socials.facebook")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href={t("footer.socials.twitter")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href={t("footer.socials.instagram")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href={t("footer.socials.linkedin")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
