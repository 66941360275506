import React from "react";
import { useTranslation } from "react-i18next"; // Import the translation hook
import "../css/WhoWeAre.css";

const WhoWeAre = () => {
  const { t } = useTranslation(); // Access translations

  return (
    <section id="who-we-are" className="who-we-are-section">
      <div className="container">
        <div className="content">
          <h2>{t("whoWeAre.title")}</h2>
          <p>
            {t("whoWeAre.paragraph1", {
              brand: <strong>Sword Garage</strong>, // Dynamic brand name
            })}
          </p>
          <p>{t("whoWeAre.paragraph2")}</p>
        </div>
        <div className="team-photo">
          <img
            src="https://i.ibb.co/7kw6T1m/40.webp" // Replace with an actual image URL
            alt={t("whoWeAre.teamPhotoAlt")}
          />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
