import React, { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa"; // Importing icons
import { useTranslation } from "react-i18next"; // Translation hook
import "../css/OurProducts.css";

const OurProducts = () => {
  const { t } = useTranslation();

  const [liked, setLiked] = useState(Array(6).fill(false)); // State to track likes for each product
  const [likeCounts, setLikeCounts] = useState([250, 180, 320, 400, 150, 275]); // Initial like counts for each product

  const toggleLike = (index) => {
    const newLiked = [...liked];
    const newLikeCounts = [...likeCounts];
    if (newLiked[index]) {
      newLikeCounts[index] -= 1; // Decrease count if unliked
    } else {
      newLikeCounts[index] += 1; // Increase count if liked
    }
    newLiked[index] = !newLiked[index]; // Toggle like state
    setLiked(newLiked);
    setLikeCounts(newLikeCounts);
  };

  const products = [
    {
      name: t("ourProducts.products.0.name"),
   
      description: t("ourProducts.products.0.description"),
      img: "https://liquimoly.cloudimg.io/v7/https://www.liqui-moly.com/media/catalog/product/cache/7c4066dd86a6b9217681a7aaaa67e822/2/3/2331_8e3f.png?w=200&h=200&func=bound&sharp=1&org_if_sml=1&force_format=webp%252Coriginal",
    },
    {
      name: t("ourProducts.products.1.name"),
    
      description: t("ourProducts.products.1.description"),
      img: "https://www.pgfilters.com/wp-content/uploads/2023/02/Bypass-valve-oil-filter-tip.jpg",
    },
    {
      name: t("ourProducts.products.2.name"),
      
      description: t("ourProducts.products.2.description"),
      img: "https://m.media-amazon.com/images/I/61MVfBRd6sL._AC_UF1000,1000_QL80_.jpg",
    },
    {
      name: t("ourProducts.products.3.name"),
 
      description: t("ourProducts.products.3.description"),
      img: "https://cdn11.bigcommerce.com/s-svnjf7qbac/images/stencil/1280x1280/products/1561/8935/AnyConv.com__9947LR_800x600__89712.1611936790.jpg?c=2",
    },
    {
      name: t("ourProducts.products.4.name"),
   
      description: t("ourProducts.products.4.description"),
      img: "https://www.fram.com/media/wysiwyg/products/FRAM-Web-AitFilter-Group_recolored.png",
    },
    {
      name: t("ourProducts.products.5.name"),
     
      description: t("ourProducts.products.5.description"),
      img: "https://steeda.ca/cdn/shop/products/2238_l.jpg?v=1490174706",
    },
  ];

  return (
    <section id="products" className="products-section">
      <h2>{t("ourProducts.title")}</h2>
      
      <div className="product-grid">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.img} alt={product.name} />
            <h3>{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price">{product.price}</p>
            <div className="actions">
              <button
                className="like-button"
                onClick={() => toggleLike(index)}
                aria-label={t("ourProducts.actions.like")}
              >
                {liked[index] ? <FaHeart className="liked" /> : <FaRegHeart />}
              </button>
              <span className="like-count">
                {likeCounts[index]} {t("ourProducts.actions.likes")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurProducts;
