import React from "react";
import "../css/Testimonials.css";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t, i18n } = useTranslation();

  // Fetch testimonials data from translations
  const testimonials = t("testimonials.testimonials", { returnObjects: true });

  return (
    <section
      id="testimonials"
      className="testimonials-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <h2>{t("testimonials.title")}</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img
              src={testimonial.img}
              alt={testimonial.name}
              className="testimonial-img"
            />
            <h3>{testimonial.name}</h3>
            <p className="location">{testimonial.location}</p>
            <p className="review">"{testimonial.review}"</p>
            <p className="rating">{"⭐".repeat(testimonial.rating)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
