import React, { useState } from "react";
import { FaTools, FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../css/Header.css";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to change language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const htmlTag = document.documentElement;

    // Set text direction and language attribute
    if (lng === "ar" || lng === "ku") {
      htmlTag.setAttribute("dir", "rtl");
    } else {
      htmlTag.setAttribute("dir", "ltr");
    }
    htmlTag.setAttribute("lang", lng);
  };

  return (
    <header className="header">
      <div className="container">
        {/* Logo with an icon */}
        <div className="logo">
          <FaTools className="logo-icon" /> {t("header.title")}
        </div>

        {/* Hamburger menu for mobile */}
        <button className="menu-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Navigation links */}
        <nav className={`nav ${isMobileMenuOpen ? "nav-open" : ""}`}>
          <a href="#services" onClick={() => setIsMobileMenuOpen(false)}>
            {t("header.services")}
          </a>
          <a href="#products" onClick={() => setIsMobileMenuOpen(false)}>
            {t("header.products")}
          </a>
          <a href="#faq" onClick={() => setIsMobileMenuOpen(false)}>
            {t("header.faq")}
          </a>
          <a href="#testimonials" onClick={() => setIsMobileMenuOpen(false)}>
            {t("header.testimonials")}
          </a>
          <a
            className="quote-button"
            href="#callback"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            {t("header.getQuote")}
          </a>

          {/* Language Selector with Flags */}
          <div className="language-switcher">
            <img
              src="https://flagcdn.com/w320/us.png"
              alt="English"
              onClick={() => changeLanguage("en")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/fr.png"
              alt="French"
              onClick={() => changeLanguage("fr")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/tr.png"
              alt="Turkish"
              onClick={() => changeLanguage("tr")}
              className="language-flag"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/640px-Flag_of_Kurdistan.svg.png"
              alt="Kurdish"
              onClick={() => changeLanguage("ku")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/es.png"
              alt="Spanish"
              onClick={() => changeLanguage("es")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/al.png"
              alt="Albanian"
              onClick={() => changeLanguage("sq")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/iq.png"
              alt="Arabic"
              onClick={() => changeLanguage("ar")}
              className="language-flag"
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
